import { createSelector } from 'reselect';
import { LetterInfoType } from '~/models';
import { LetterStateWithRoot } from '~/store/reducers';

export const selectLetterState = (state: LetterStateWithRoot) => state.letter;

export const selectLetterEmail = createSelector(selectLetterState, (state) => state.email);
export const selectLetterGroomInfo = createSelector(selectLetterState, (state) => state.groom);
export const selectLetterPriestInfo = createSelector(selectLetterState, (state) => state.priest);
export const selectLetterPlaceInfo = createSelector(selectLetterState, (state) => state.place);
export const selectLetterWayToComeInfo = createSelector(
  selectLetterState,
  (state) => state.wayToCome,
);
export const selectLetterNoteInfo = createSelector(selectLetterState, (state) => state.note);
export const selectLetterPhotoInfo = createSelector(selectLetterState, (state) => state.photo);
export const selectLetterAccountsInfo = createSelector(
  selectLetterState,
  (state) => state.accounts,
);
export const selectLetterThemeInfo = createSelector(selectLetterState, (state) => state.theme);
export const selectLetterOGInfo = createSelector(selectLetterState, (state) => state.og);
export const selectLetterYoutubeLink = createSelector(
  selectLetterState,
  (state) => state.youtubeLink,
);
export const selectLetterYoutubeLiveLink = createSelector(
  selectLetterState,
  (state) => state.youtubeLiveLink,
);
export const selectLetterAttendsInfo = createSelector(selectLetterState, (state) => state.attends);
export const selectLetterGuestBooksInfo = createSelector(
  selectLetterState,
  (state) => state.guestBooks,
);

export const selectIsLetterEmailValid = createSelector(
  selectLetterState,
  (state) => state.emailValid,
);
export const selectIsLetterGroomInfoValid = createSelector(
  selectLetterState,
  (state) => state.groomValid,
);
export const selectIsLetterPriestInfoValid = createSelector(
  selectLetterState,
  (state) => state.priestValid,
);
export const selectIsLetterPlaceInfoValid = createSelector(
  selectLetterState,
  (state) => state.placeValid,
);
export const selectIsLetterNoteInfoValid = createSelector(
  selectLetterState,
  (state) => state.noteValid,
);
export const selectIsLetterPhotoInfoValid = createSelector(
  selectLetterState,
  (state) => state.photoValid,
);
export const selectIsLetterAccountsInfoValid = createSelector(
  selectLetterState,
  (state) => state.accountsValid,
);
export const selectIsLetterThemeInfoValid = createSelector(
  selectLetterState,
  (state) => state.themeValid,
);
export const selectIsLetterOGInfoValid = createSelector(
  selectLetterState,
  (state) => state.ogValid,
);
export const selectIsLetterAttendsInfoValid = createSelector(
  selectLetterState,
  (state) => state.attendsValid,
);

export const selectPreviewAvailable = createSelector(
  [
    selectIsLetterEmailValid,
    selectIsLetterGroomInfoValid,
    selectIsLetterPriestInfoValid,
    selectIsLetterPlaceInfoValid,
    selectIsLetterNoteInfoValid,
    selectIsLetterPhotoInfoValid,
    selectIsLetterAccountsInfoValid,
    selectIsLetterAttendsInfoValid,
    selectIsLetterThemeInfoValid,
  ],
  (...validations) => validations.every((x) => x),
);

export const selectLetterInfoValidationMap = createSelector(
  selectLetterState,
  (state): Record<LetterInfoType, boolean> => {
    const {
      emailValid,
      groomValid,
      priestValid,
      placeValid,
      noteValid,
      photoValid,
      accountsValid,
      attendsValid,
      themeValid,
      ogValid,
    } = state;

    return {
      email: true,
      groom: emailValid,
      priest: emailValid && groomValid,
      place: emailValid && groomValid && priestValid,
      note: emailValid && groomValid && priestValid && placeValid,
      photo: emailValid && groomValid && priestValid && placeValid && noteValid,
      accounts: emailValid && groomValid && priestValid && placeValid && noteValid && photoValid,
      etc:
        emailValid &&
        groomValid &&
        priestValid &&
        placeValid &&
        noteValid &&
        photoValid &&
        accountsValid,
      theme:
        emailValid &&
        groomValid &&
        priestValid &&
        placeValid &&
        noteValid &&
        photoValid &&
        accountsValid &&
        attendsValid,
      pay:
        emailValid &&
        groomValid &&
        priestValid &&
        placeValid &&
        noteValid &&
        photoValid &&
        accountsValid &&
        attendsValid &&
        themeValid &&
        ogValid,
    };
  },
);
