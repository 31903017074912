import { createQueryString } from '@florencecard-lib/query-string';
import { IamportPayMethod } from '~/core';
import {
  LetterData,
  LetterGuestBook,
  LetterShareInfo,
  LetterWithData,
  Payment,
  VBankInfo,
} from '~/models';
import { Paginated } from '~/models/pagination';
import { api, baseApiUrl } from '~/remotes/api';

export const verifyPaymentApiUrl = `${baseApiUrl}/letters.payments.verify`;

export async function createPayment(payload: {
  pg: string;
  payMethod: IamportPayMethod;
  email: string;
  phone: string;
  letterObject: string;
  letterTheme: string;
}) {
  const { data } = await api.post<Payment>('/letters.create', payload);

  return data;
}

export async function previewLetter(payload: { theme: string; data: LetterData }) {
  const { data } = await api.post<{ html: string }>('/letters.preview', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}

export async function checkLetterBuilt(payload: { orderId: string }) {
  const { data } = await api.get<boolean>(`/letters.checkBuilt?orderId=${payload.orderId}`);

  return data;
}

export async function getLetterShareInfoByOrderId(payload: { orderId: string }) {
  const { data } = await api.get<LetterShareInfo>(
    `/letters.getShareInfoByOrderId?orderId=${payload.orderId}`,
  );

  return data;
}

export async function findLetterShareInfoListByNameAndPhone(payload: {
  name: string;
  phone: string;
}) {
  const { name, phone } = payload;
  const { data } = await api.get<LetterShareInfo[]>(
    `/letters.findShareInfoListByNameAndPhone?name=${encodeURIComponent(name)}&phone=${phone}`,
  );

  return data;
}

export async function getPaymentVBankInfo(orderId: string) {
  const { data } = await api.get<VBankInfo>(`/letters.payments.getVBankInfo?orderId=${orderId}`);

  return data;
}

export async function getLetterPrice() {
  const { data } = await api.get<{ price: number }>('/letters.payments.getPrice');

  return data.price;
}

export async function getLetterByOrderId(orderId: string) {
  const { data } = await api.get<LetterWithData>(`/letters.getByOrderId?orderId=${orderId}`);

  return data;
}

export async function editLetter(payload: { orderId: string; letterData: LetterData }) {
  const { orderId, letterData } = payload;

  await api.post(`/letters.edit`, {
    orderId,
    letterData: JSON.stringify(letterData),
  });
}

export async function getRefundBankCodes() {
  const { data } = await api.get<Record<string, string>>('/letters.payments.getRefundBankCodes');

  return data;
}

export async function cancelLetter(payload: {
  orderId: string;
  reason: string;
  refundHolder?: string;
  refundBankCode?: string;
  refundAccount?: string;
}) {
  await api.post<true>(`/letters.payments.cancel`, payload);
}

export async function listLetterGuestBooks(payload: {
  page?: number;
  size?: number;
  letterOrderIdQuery?: string;
  sortBy?: 'createdAt' | 'updatedAt';
  orderBy?: 'ASC' | 'DESC';
}) {
  const url = `/letters.guestBooks.list${createQueryString(payload)}`;
  const { data } = await api.get<Paginated<LetterGuestBook>>(url);

  return data;
}

export async function deleteLetterGuestBook(payload: { letterGuestBookId: number }) {
  await api.post('/letters.guestBooks.delete', payload);
}
